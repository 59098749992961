import React, { useRef, useEffect } from 'react';
import mission from '../Assets/aboutt.svg'
import vission from '../Assets/img.svg'
import about from '../Assets/new/about.svg'
import AOS from 'aos';
import { Link } from 'react-router-dom';
import ai from '../Assets/new/ai.svg'
import 'aos/dist/aos.css';
import ex1 from '../Assets/11 (1).png'
import ex2 from '../Assets/2.png'
import ex3 from '../Assets/Group 1597883027.png'
import ex4 from '../Assets/Group 1597883026.png'

const About = () => {

	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);



	return (
		<div className='about-page'>
			<div className="common-banner b">
				<div className="container-main">
					<h1 className='banner-title'>About Us</h1>
					<p className='sub-title-banner'>Discover our journey and commitment to delivering excellence</p>
				</div>
			</div>
			<div className="aboutus-section">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box btmm" data-aos="fade-right">
								<img src={about} className='about-image' alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box" data-aos="fade-left">
								<h4 className='title-about-us'>About Us</h4>
								<h2 className='title-about-us-main'>Who We Are</h2>
								<p className='sub-t-about-us'> Arabian Greens International (AGI) is a premier general
									trading company based in the UAE, offering a wide
									range of products and services across various industries
									worldwide. Our portfolio includes general trading, technical
									services, facility management, and IT services, ensuring a
									comprehensive solution for all our clients under one roof.

								</p>
								<p className='sub-t-about-us'> We prioritize consumer safety and maintain a robust
									quality management system throughout our operations
									from procurement and warehousing to distribution. Our
									dedication to excellence drives us to continuously improve
									our standards, aiming to achieve complete customer
									satisfaction and enhance our processes and systems.

								</p>
								{/* <div className="border-dv"></div> */}
								<Link to='/' className='btn-home-explore'>More about us</Link>
								<Link to='/' className='btn-home-download-a'>Download Brochure</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="exclusive">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex4} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>K-BioGreen Organic
								Food Supplement</h5>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex2} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>Zooki Collagen
and <br /> Turmeric 
</h5>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex3} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>Nu-Calgon HVAC
								Odor Block XL</h5>
							</div>
						</div>
						
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex1} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>Safety and Uniform <br /> Items
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About