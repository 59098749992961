import React, { useRef, useEffect } from 'react';
import cart from '../Assets/icons/cart.png'
import cafeteria from '../Assets/icons/cafeteria.png'
import dinner from '../Assets/icons/dinner.png'
import hotel from '../Assets/icons/hotel.png'
import seller from '../Assets/icons/seller.png'
import table from '../Assets/icons/table.png'
import worker from '../Assets/icons/worker.png'
import cuisine from '../Assets/icons/cuisine.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Customers = () => {

	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);



	return (
		<div className='about-page'>
			<div className="container-main">
				<div className="row">
					<div className="col-lg-12">
						<div className="banner-box">
							<h2 className='our-products-title'>Our Customers</h2>
							<p className='our-products-sub-title'>Every success of a business is attributed to its customer, we as a company, gives priority in fulfilling all customer needs with greatest pliability and ensure continuous delight for our customers by always staying a step ahead of the curve.</p>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="space"></div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={cart} className='cust-icon' alt="" />
							<h3>Super Markets							</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={cafeteria} className='cust-icon' alt="" />
							<h3>Restaurants			</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={dinner} className='cust-icon' alt="" />
							<h3>Individuals						</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={hotel} className='cust-icon' alt="" />
							<h3>Hotels					</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={seller} className='cust-icon' alt="" />
							<h3>Grocery					</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={table} className='cust-icon' alt="" />
							<h3>Canteens					</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={worker} className='cust-icon' alt="" />
							<h3>Labour Accomodation							</h3>
						</div>
					</div>
					<div className="col-lg-3 col-6">
						<div className="customers-box" data-aos="zoom-in">
							<img src={cuisine} className='cust-icon' alt="" />
							<h3>Cafeterias						</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Customers