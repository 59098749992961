import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import tech from '../Assets/new/project-management.png'
import ai from '../Assets/new/ai.svg'
import it from '../Assets/new/settings.png'
import general from '../Assets/new/business-and-trade.png'
import fac from '../Assets/new/facility.png'
import ex1 from '../Assets/11 (1).png'
import ex2 from '../Assets/2.png'
import ex3 from '../Assets/Group 1597883027.png'
import ex4 from '../Assets/Group 1597883026.png'
const Services = () => {

  useEffect(() => {
    AOS.init({
      duration: 2000, // Animation duration in milliseconds
      once: true, // Trigger animation only once
    });

    window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

    return () => {
      window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
    };
  }, []);


  return (
    <div className='about-page'>
      <div className="common-banner e">
        <div className="container-main">
          <h1 className='banner-title'>Our Services</h1>
          <p className='sub-title-banner'>Empowering businesses with tailored solutions to drive growth</p>
        </div>
      </div>
      <div className="aboutus-section">
        <div className="container-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="services-inner" data-aos="zoom-in-down">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="srvc">
                      <img src={general} className='service-icon' alt="" />
                      <h4 className='servc-name'>General Trading Services</h4>
                      <p className='servc-sub-name'>Experience comprehensive general trading services that cater to a diverse range of products and solutions, ensuring quality, reliability, and efficiency in every transaction.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="srvc">
                      <img src={tech} className='service-icon' alt="" />
                      <h4 className='servc-name'>Technical Service</h4>
                      <p className='servc-sub-name'>Access top-notch technical services that provide expert support, maintenance, and solutions for your technological needs, ensuring optimal performance and reliability</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="srvc">
                      <img src={it} className='service-icon' alt="" />
                      <h4 className='servc-name'>IT Services</h4>
                      <p className='servc-sub-name'>Transform your business with our comprehensive IT services, offering cutting-edge solutions in software development, network management, cybersecurity.</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="srvc">
                      <img src={fac} className='service-icon' alt="" />
                      <h4 className='servc-name'>Facility Management Services </h4>
                      <p className='servc-sub-name'>Ensure a well-maintained and efficient environment with our facility management services, providing comprehensive solutions for cleaning, maintenance, security.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>










      <div className="exclusive">
        <div className="container-main">
          <div className="row">
            <div className="col-lg-12">
              <div className="exclusive-box">
                <div className="exclusv-half">
                  <h2 className='exclusive-text'>Exclusive</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="exclusive-items-main">
                <img src={ex4} className='itemz-img-ex' alt="" />
                <h5 className='items-ex'>K-BioGreen Organic
                  Food Supplement</h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="exclusive-items-main">
                <img src={ex2} className='itemz-img-ex' alt="" />
                <h5 className='items-ex'>Zooki Collagen
                  and <br /> Turmeric
                </h5>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="exclusive-items-main">
                <img src={ex3} className='itemz-img-ex' alt="" />
                <h5 className='items-ex'>Nu-Calgon HVAC
                  Odor Block XL</h5>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="exclusive-items-main">
                <img src={ex1} className='itemz-img-ex' alt="" />
                <h5 className='items-ex'>Safety and Uniform <br /> Items
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services;