import React, { useRef, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner from '../Assets/banner.svg'
import about from '../Assets/new/about.svg'
import love from '../Assets/new/love.png'
import leave from '../Assets/new/leaf.png'
import qul from '../Assets/new/quality.png'
import ai from '../Assets/new/ai.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import val from '../Assets/new/7.jpg'
import offer from '../Assets/new/5.jpg'
import offer2 from '../Assets/new/6.jpg'
import offer3 from '../Assets/new/4.jpg'
import ex1 from '../Assets/11 (1).png'
import ex2 from '../Assets/2.png'
import ex3 from '../Assets/Group 1597883027.png'
import ex4 from '../Assets/Group 1597883026.png'
import pdf from '../Assets/arabian-greens-brochure.pdf'



const Home = () => {

	var settingsBanner = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	var settingsClients = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		pauseOnHover: false,
		arrows: false,
		responsive: [

			{
				breakpoint: 768, // At screen width 768px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // At screen width 480px and below
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};


	useEffect(() => {
		AOS.init({
			duration: 2000, // Animation duration in milliseconds
			once: true, // Trigger animation only once
		});

		window.addEventListener('scroll', AOS.refresh); // Refresh AOS on scroll

		return () => {
			window.removeEventListener('scroll', AOS.refresh); // Cleanup on component unmount
		};
	}, []);

	return (
		<div className="home-page">
			<Slider  {...settingsBanner} className=''>
				<div className='bg-mob onebgg'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Experience</h3>
						<h3 className='heroo'>Excellence Every Day</h3>
						<h2 className='sub-hero'>Arabian Greens International (AGI) is mainly operating in General trading, Building Materials, provision of IT and, Technical services, Facility management and much more.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob twobg'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Electronics and
						</h3>
						<h3 className='heroo'>Home Appliances</h3>
						<h2 className='sub-hero'>At AGI, we provide an extensive range of electronics and home appliances, including the
							latest in smart technology and energy-efficient devices. From home entertainment
							systems to essential kitchen appliances, our products are designed to meet modern
							household needs with reliability and style.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className=' bg-mob threebg'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Home Decor</h3>
						{/* <h3 className='heroo'>Excellence Every Day</h3> */}
						<h2 className='sub-hero'>Enhance your living spaces with our curated selection of home décor
							items. Whether you’re looking for modern, minimalist, or classic designs,
							our range includes everything from furniture and lighting to decorative
							pieces that add charm and sophistication to any room.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob frbg'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>IT & Mobile </h3>
						<h3 className='heroo'>Accessories</h3>
						<h2 className='sub-hero'>Your go-to destination for premium IT solutions and cutting-edge mobile accessories. We provide a wide range of products, from advanced tech gadgets to essential mobile gear, ensuring seamless connectivity and style. Elevate your tech experience with quality, innovation, and unmatched service.</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
				<div className='bg-mob fvbg'>
					<div className="item-content" data-aos="zoom-in">
						<h3 className='heroo'>Cleaning
							Material
						</h3>
						<h3 className='heroo'>and Equipments</h3>
						<h2 className='sub-hero'>Cleaning products are designed to
							provide effective solutions for both
							home and industrial environments.
							From eco-friendly cleaning agents
							to heavy-duty industrial cleaners,
							we ensure that our range meets the
							highest standards of hygiene and
							safety</h2>
						<Link to='/about' className='btn-home-explore'>About Us</Link>
						<a href={pdf} className="btn-home-download" download>
							Download Brochure
						</a>								</div>
				</div>
			</Slider>
			<div className="marquee">
				<div className="container-main">
					<div className="row">3
						<div className="col-lg-12">
							<div className="moving-text">
								<marquee width="100%" direction="left" height="100%" behavior="scroll" scrollamount="3">
									<p ><span className='text-moving'>Fresh Groceries</span><span className='text-moving'>Home Appliances & Electronics</span><span className='text-moving'>Cleaning Supplies</span><span className='text-moving'>Beauty & Cosmetics</span><span className='text-moving'>Office & School Stationery</span></p>
								</marquee>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="aboutus-section">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box btmm" data-aos="fade-right">
								<img src={about} className='about-image' alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="about-inner-box" data-aos="fade-left">
								<h4 className='title-about-us'>About Us</h4>
								<h2 className='title-about-us-main'>Who We Are</h2>
								<p className='sub-t-about-us'> Arabian Greens International (AGI) is a premier general
									trading company based in the UAE, offering a wide
									range of products and services across various industries
									worldwide. Our portfolio includes general trading, technical
									services, facility management, and IT services, ensuring a
									comprehensive solution for all our clients under one roof.

								</p>
								<p className='sub-t-about-us'> We prioritize consumer safety and maintain a robust
									quality management system throughout our operations
									from procurement and warehousing to distribution. Our
									dedication to excellence drives us to continuously improve
									our standards, aiming to achieve complete customer
									satisfaction and enhance our processes and systems.

								</p>
								<div className="border-dv"></div>
								<Link to='/' className='btn-home-explore'>More about us</Link>
								<Link to='/' className='btn-home-download-a'>Download Brochure</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="why-shop">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="shop-main">
								<h1 className='shop-with-txt'>Why Shop With Us?</h1>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="bg-shop" data-aos="zoom-in-down">
								<div className="shop-half">
									<img src={qul} className='shop-img' alt="" />
									<h4 className='shop-title'>Unmatched Quality</h4>
									<p className='shop-sub-title'>We believe in delivering only the
										best. From groceries to electronics,
										we maintain the highest standards.</p>
								</div>
								<div className="shop-half">
									<img src={leave} className='shop-img' alt="" />
									<h4 className='shop-title'>Diverse Product Range</h4>
									<p className='shop-sub-title'>A one-stop shop for all your
										needs—whether it's groceries, home
										essentials, or cosmetics</p>
								</div>
								<div className="shop-half">
									<img src={love} className='shop-img' alt="" />
									<h4 className='shop-title'>Customer-Centric Approach</h4>
									<p className='shop-sub-title'>Your satisfaction is our priority. We
										go above and beyond to ensure a
										hassle-free shopping experience.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="values">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="values-sub" data-aos="fade-up" data-aos-duration="1500">
								<h1 className='our-values'>Our Values</h1>
								<h3 className='our-values-title'>Sustainability</h3>
								<p className='our-values-sub'>To offer the finest quality products with exceptional
									customer service, ensuring that our customers’ needs are
									met with care and dedication.</p>
								<h3 className='our-values-title'>Innovation</h3>
								<p className='our-values-sub'>To be a trusted name in retail, providing a diverse range
									of products and services that bring convenience and
									satisfaction to our customers.
								</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="values-sub-img" data-aos="fade-up" data-aos-duration="1500">
								<img src={val} className='value-img' alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="what-we-offer">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<h1 className='wh'>What We Offer</h1>
							<div className="offer-main">
								<Slider  {...settingsClients}>
									<div className="offer-slider">
										<img src={offer} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Home Appliances & Electronics							</h3>
											<p className='item-subb'>we provide an extensive range of electronics and home appliances, including the latest in smart technology and energy-efficient devices.</p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer3} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Cosmetics									</h3>
											<p className='item-subb'>Our cosmetics line features products that are not only luxurious but also safe and effective.From skincare essentials to beauty enhancers. </p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer2} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>Cleaning Material & Equipments							</h3>
											<p className='item-subb'>Cleaning products are designed to provide effective solutions for both home and industrial environments. </p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
									<div className="offer-slider">
										<img src={offer} className='offer-img-sldr' alt="" />
										<div className="items-boxx">
											<h3 className='item-namee'>IT & Mobile Accessories								</h3>
											<p className='item-subb'>Your go-to destination for premium IT solutions and cutting-edge mobile accessories. We provide a wide range of products, from advanced tech gadgets to essential mobile gear</p>
											<Link to='/' className='btn-home-shop'>Shop Now</Link>
										</div>
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="exclusive">
				<div className="container-main">
					<div className="row">
						<div className="col-lg-12">
							<div className="exclusive-box">
								<div className="exclusv-half">
									<h2 className='exclusive-text'>Exclusive</h2>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex4} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>K-BioGreen Organic
								Food Supplement</h5>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex2} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>Zooki Collagen
and <br /> Turmeric 
</h5>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex3} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>Nu-Calgon HVAC
								Odor Block XL</h5>
							</div>
						</div>
						
						<div className="col-lg-3">
							<div className="exclusive-items-main">
								<img src={ex1} className='itemz-img-ex' alt="" />
								<h5 className='items-ex'>Safety and Uniform <br /> Items
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>























		</div>
	)
}

export default Home