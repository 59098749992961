import React, { useState, useEffect } from 'react';
import Navbar from '../Outlets/Navbar';
import Footer from '../Outlets/Footer';
import { Outlet } from 'react-router-dom';
import logo from '../Assets/log.svg'

const Layout = () => {  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show splash screen for 1 second on page refresh
    const timer = setTimeout(() => {
      setLoading(false);
    }, 900);

    // Clean up the timer when component unmounts
    return () => clearTimeout(timer);
  }, []);

  // Splash screen component within Layout
  const SplashScreen = () => {
    return (
      <div style={styles.splashScreen}>
        <img src={logo} alt="Logo" style={styles.logo} />
      </div>
    );
  };

  const styles = {
    splashScreen: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: '#fff',  // Splash screen background color
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
    logo: {
      width: '300px',  // Adjust logo size
    },
  };

  return (
    <div>
      {loading ? (
        <SplashScreen />  // Show the splash screen while loading is true
      ) : (
        <>
          <Navbar />
          <Outlet />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
