import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../Pages/Layout';
import Home from '../Pages/Home';
import Products from '../Pages/Products';
import Services from '../Pages/Services';
import Contact from '../Pages/Contact';
import About from '../Pages/About';
import Customers from '../Pages/Customers';
const AppRouter = () => {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/" element={<Layout />} >
						<Route index element={<Home />} />
						<Route path="/products"  index element={<Products />} />
						<Route path="/services"  index element={<Services />} />
						<Route path="/contact"  index element={<Contact />} />
						<Route path="/about"  index element={<About />} />
						<Route path="/customers"  index element={<Customers />} />
					</Route>
				</Routes>
			</Router>
		</div>
	)
}

export default AppRouter